
/**
 * @name: 销售管理-售价预警
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 销售管理-售价预警
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import {dictDetailApi, dictSetUpApi} from "@/apis/sale/warning";
import {IDict} from "@/apis/sale/warning/types";

@Component({})
export default class saleWarning extends Vue {

  // 表单参数
  modelForm: any = {
    id: '',
    label: '',
    values: '',
    types: 2 //1=销售抹零设置 2=售价预警设置 3-市场名称 4-联系电话 5-市场地址 6-市场介绍
  };

  isEdit: boolean = false;

  /**
   * 显示 输入框
   */
  async showEditPrice() {
    this.isEdit = true;
  }

  /**
   * 提交修改
   * @param row
   */
  submitPrice() {
    if (!this.modelForm.values || this.modelForm.values=='') {
      this.$message({
        type: 'error',
        message: '请输入最低百分比！'
      });
      return
    }
    let obj: IDict = this.modelForm as any;
    dictSetUpApi(obj).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.isEdit = false;
        this.getDetail();
      }
    })

  }

  /**
   * 获取详情
   */
  getDetail() {
    dictDetailApi(this.modelForm.types).then(e => {
      if (e) {
        this.modelForm.values = e.value;
        this.modelForm.id = e.id;
        this.modelForm.label = e.label;
      }
    })
  }

  created() {
    this.getDetail()
  }
}
