/**
 * @name: 销售管理-售价预警接口文件
 * @author:  lili
 * @date: 2023-08-04 17:46
 * @description： 销售管理-售价预警接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IDict} from "@/apis/sale/warning/types";

/**
 *  设置
 * @param types
 * @returns
 */
export const dictSetUpApi = (param: IDict) => get<IDict>("/admin/dict/setUp",param)

/**
 *  获取详情
 * @param types
 * @returns
 */
export const dictDetailApi = (types: string) => get<IDict>("/admin/dict/detail",{types})
